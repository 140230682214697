<template>
    <Consolidation />
</template>

<script>
import Consolidation from '@apps/Consolidation'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Consolidation,
    }
}
</script>