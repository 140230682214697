<template>
    <div 
        class="comment_file" 
        :title="file.name">
        <a 
            v-if="isImage"
            class="lht_l"
            :href="file.path">
            <img
                class="lazyload"
                :data-src="file.path" 
                :alt="file.name" />
        </a>
        <template v-else>
            <a
                download
                class="truncate doc_file"
                target="_blank"
                :href="file.path">
                <a-tooltip :title="fileName">
                    <img 
                        :src="fileIcon"
                        class="file_icon" />
                    <span class="truncate">
                        {{ fileName }}
                    </span>
                </a-tooltip>
            </a>
        </template>

        <a-modal
            :title="$t('image_show')"
            :visible="visible"
            :zIndex="5000"
            :footer="null"
            @cancel="handleCancel">
            <div 
                v-if="modalImage" 
                class="cm_modal_image">
                <img 
                    :src="modalImage.path" 
                    :alt="modalImage.name" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { filesFormat } from '@/utils'
export default {
    props: {
        file: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            modalImage: null,
            visible: false
        }
    },
    computed: {
        fileIcon() {
            const find = filesFormat.find(f => f === this.file.extension)
            if(find)
                return require(`@/assets/images/files/${this.file.extension}.svg`)
            else
                return require(`@/assets/images/files/file.svg`)
        },
        fileName() {
            if(this.file.name) {
                return `${this.file.name}.${this.file.extension}`
            } else {
                return this.$t('file')
            }
        },
        office() {
            switch (this.file.extension) {
            case "doc":
                return 'docx'
                break;
            case "docx":
                return 'docx'
                break;
            case "xlsx":
                return 'xlsx'
                break;
            case "xls":
                return 'xlsx'
                break;
            case "pptx":
                return 'pptx'
                break;
            case "ppt":
                return 'pptx'
                break;
            default:
                return ''
            }
        },
        isDoc() {
            switch (this.file.extension) {
            case "doc":
                return true
                break;
            case "docx":
                return true
                break;
            case "xlsx":
                return true
                break;
            case "xls":
                return true
                break;
            case "pptx":
                return true
                break;
            case "ppt":
                return true
                break;
            default:
                return false
            }
        },
        isImage() {
            return this.file.is_image
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },
    methods: {
        handleCancel() {
            this.visible = false
            this.modalImage = null
        },
        openFile() {
            if(this.isImage) {
                //this.modalImage = this.file
                //this.visible = true
            } else
                window.open(this.file.path, '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.cm_modal_image{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 100%;
    }
}
.doc_modal{
    &::v-deep{
        .ant-modal-body{
            padding: 0px;
            height: calc(100% - 36px);
        }
        .ant-modal{
            padding: 0px;
            height: 100vh;
        }
        .ant-modal-content{
            height: 100%;
            border-radius: 0px;
        }
        .ant-modal-wrap{
            overflow: hidden;
        }
        .ant-modal-header{
            padding: 7px 18px;
            border-radius: 0px;
            border-bottom: 0px;
            .ant-modal-title{
                font-size: 14px;
            }
        }
        .ant-modal-close-x{
            height: 36px;
            width: 36px;
            line-height: 30px;
        }
    }
}
.comment_file{
    width: 80px;
    height: 80px;
    border-radius: var(--borderRadius);
    overflow: hidden;
    border: 1px solid var(--border2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    transition : border 200ms ease-out;
    background: #fafafa;
    .doc_file{
        padding: 0 5px;
    }
    .file_icon{
        max-width: 40px;
        margin: 0 auto;
    }
    a{
        &.lht_l{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            img{
                transition: opacity 0.15s ease-in-out;
                &:not(.lazyloaded){
                    opacity: 0;
                }
            }
        }
        &:not(.lht_l){
            display: block;
        }
        color: #505050;
    }
    &:hover{
        border-color: var(--blue);
    }
    span{
        font-size: 12px;
        font-weight: 300;
        display: block;
    }
    img{
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        -o-object-fit: cover;
    }
    &:not(:last-child){
        margin-right: 5px;
    }
}
</style>